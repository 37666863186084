import Axios from "axios";
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import * as API from "../../api/api";
import { routes } from "../../config/routes";
import { setHasRights } from "../auth";
import { selectReportsFilter } from "../filters/selectors";
import {
  loadRecommendationsActions,
  removeAllRecommendations,
} from "./recommendations";

export function* loadRecommendationsSaga() {
  yield takeLatest(
    loadRecommendationsActions.trigger,
    function* (a: ReturnType<typeof loadRecommendationsActions.trigger>) {
      // const routerLocation = yield select((s) => s.router.location);
      // const recommendationsMatchPath = routes.recommendations.matchPath(
      //   routerLocation
      // );
      // if (!recommendationsMatchPath) {
      //   yield cancel();
      // }
      const { skip, top, reset } = a.payload;
      if (reset) {
        yield put(removeAllRecommendations());
      }
      yield delay(300);
      const cancelSource = Axios.CancelToken.source();
      const clientsFilter = yield select((s) => s.filters.clientsFilter);
      const locationsFilter = yield select((s) => s.filters.locationsFilter);
      const reportsFilter = selectReportsFilter(yield select());
      const search = yield select((s) => s.recommendationsPage.search);
      const sort = yield select((s) => s.recommendationsPage.sort);
      const columns = yield select((s) => s.recommendationsPage.columns);

      try {
        yield put(loadRecommendationsActions.loading());
        let res = yield call(API.searchRecommendations as any, {
          top,
          skip,
          reset,
          clientsFilter,
          locationsFilter,
          reportsFilter,
          search,
          sortDir: sort.dir,
          sortKey: sort.key,
          cancelToken: cancelSource.token,
          columns,
        });

        yield put(loadRecommendationsActions.success({ res: res.data, reset }));
      } catch (err) {
        console.log(err);
        if (err.response.status === 403) {
          yield put(setHasRights(false));
        }
        yield put(loadRecommendationsActions.error(err));
      } finally {
        if (yield cancelled()) {
          cancelSource.cancel();
        }
      }
    }
  );
}

export function* recommendationsSaga() {
  yield fork(loadRecommendationsSaga);
}
