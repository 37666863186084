import { Icon, TooltipOverflowMode } from "@fluentui/react";
import React from "react";
import { Link } from "react-router-dom";
import { CenteredCell, HSpace, Truncate } from "../../components/styled";
import {
  accent,
  getRecommendationStatusCircleColor,
  recommendationStatusColors,
} from "../../config/colors";
import { routes } from "../../config/routes";
import { setTab } from "../../store/recommendationPage";
import { store } from "../../store/store";
import {
  isFilteredDate,
  isFilteredNumber,
  isFilteredTaxonomy,
  isFilteredText,
  transformSicCode,
} from "../../utils/columns";
import { formatNumber } from "../../utils/number";
import * as RecommendationUtils from "../../utils/recommendation";
import { formatDateString } from "../../utils/utils";
import { ContextButton } from "./ContextButton";
import { StatusCell } from "./StatusCell";
import { StatusIndicators } from "./StatusIndicators";

import i18n, { TFunction } from "i18next";
import { TaxonomyState } from "../../store/taxonomy/taxonomy";
import { RecommendationAzure } from "../../types/types";
import FixedCell from "./FixedCell";
import TranslatedTaxonomyCell from "./TranslatedTaxonomyCell";
import TooltipWithContentCell from "./TooltipWithContentCell";
import { TooltipComponent } from "components/TooltipComponent";
import { ExpandedColumn } from "types/columns";

export const defaultCols: ExpandedColumn[] = [
  {
    key: "recommendationStatusCode",
    labelKey: "ram.recommendations.column.recommendationStatusCode.label",
    isVisible: true,
    fieldName: "recommendationStatusCode",
    isResizable: false,
    name: "",
    isSortable: true,
    maxWidth: 40,
    width: 40,
    minWidth: 40,
    isFixed: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "RiskRecommendationStatus",
      value: [],
      isFiltered: isFilteredTaxonomy,
      exclude: ["RiskRecommendationStatus.ALL"],
      renderLabel: ({ taxonomy, t }) => (
        <>
          <Icon
            iconName="CircleFill"
            style={{
              color: recommendationStatusColors[taxonomy.code],
              fontSize: "8px",
            }}
          />
          <HSpace />
          <Truncate>{t(taxonomy?.code)}</Truncate>
        </>
      ),
    },
    pdf: {
      label: "",
      render: ({ recommendation, taxonomy }) => {
        const code =
          taxonomy.RiskRecommendationStatus.byId[
            recommendation.recommendationStatusCode
          ]?.code;
        const color = getRecommendationStatusCircleColor(recommendation, code);

        const isAssessedCircle =
          recommendation.isAssessed &&
          code === "RiskRecommendationStatus.WAITING_FOR_GREG";

        if (isAssessedCircle) {
          return {
            text: "",
            style: {
              font: "LineAwesome",
              fontSize: 13,
              color,
              alignment: "center",
            },
          };
        }

        return {
          text: "",
          style: {
            font: "Remix",
            fontSize: 10,
            color,
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskRecommendationStatus.byId[
            recommendation.recommendationStatusCode
          ]?.code;
        return t(code);
      },
    },
    onRender: (row, index) => {
      return (
        <FixedCell no={index} row={row}>
          <StatusCell rec={row} />
        </FixedCell>
      );
    },
  },
  {
    key: "title",
    fieldName: "title",
    isResizable: false,
    labelKey: "ram.recommendations.column.title.label",
    name: "ram.recommendations.column.title.label",
    isVisible: true,
    isSortable: true,
    isFixed: true,
    maxWidth: 300,
    width: 300,
    minWidth: 300,
    iconName: "Sort",

    pdf: {
      width: 500,
      render: ({ recommendation }) => {
        return {
          text: recommendation.title,
          style: {
            bold: true,
            alignment: "left",
          },
        };
      },
    },
    onRender: (row, index) => {
      return (
        <TooltipComponent
          content={row.title}
          overflowMode={TooltipOverflowMode.Parent}
          styles={{
            root: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              position: "relative",
              bottom: "11px",
              display: "flex",
              width: "320px",
              height: "42px",
            },
          }}
        >
          <FixedCell no={index} row={row}>
            <Link
              to={routes.editRecommendation.getPath(
                row.webBasePartnerNumber,
                row.locationId,
                row.reportId,
                row.recommendationId
              )}
              onClick={() => {
                const isWaitingForGREG = row.recommendationStatusCode === "1";
                if (isWaitingForGREG) {
                  store.dispatch(setTab("responses"));
                } else {
                  store.dispatch(setTab("responses"));
                }
              }}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              {row?.title}
            </Link>
          </FixedCell>
        </TooltipComponent>
      );
    },
  },
  {
    labelKey: "ram.recommendations.column.options.label",
    name: "",
    key: "options",
    fieldName: "options",
    isResizable: false,
    isVisible: true,
    isSortable: false,
    isFixed: true,
    maxWidth: 40,
    width: 40,
    minWidth: 40,
    onRender: (row, index) => {
      return (
        <div
          style={{
            height: "41px",
            width: "60px",
            position: "relative",
            bottom: "11px",
          }}
        >
          <FixedCell no={index} row={row}>
            <CenteredCell>
              <ContextButton rec={row} />
            </CenteredCell>
          </FixedCell>
        </div>
      );
    },
  },
  {
    key: "statusIndicators",
    fieldName: "statusIndicators",
    isResizable: true,
    labelKey: "ram.recommendations.column.statusIndicators.label",
    name: "",
    isVisible: true,
    isFixed: true,
    maxWidth: 100,
    width: 100,
    minWidth: 100,
    pdf: {
      label: "",
      render: ({ recommendation, taxonomy, t }) => {
        const columns = [];
        const statusCode =
          taxonomy.RiskRecommendationStatus.byId[
            recommendation.recommendationStatusCode
          ]?.code;

        if (recommendation.noGo) {
          columns.push({
            text: "",
            style: {
              font: "Remix",
              color: accent.redDark,
              alignment: "center",
              fontSize: 17,
            },
            width: 17,
          });
        }

        if (recommendation.hasDraftResponses) {
          columns.push({
            text: "",
            style: {
              font: "LineAwesome",
              color: accent.redDark,
              alignment: "center",
              fontSize: 18,
            },
            width: 17,
          });
        }

        const isLate = recommendation
          ? RecommendationUtils.isLate(recommendation)
          : false;

        if (
          statusCode === "RiskRecommendationStatus.WAITING_FOR_CLIENT" &&
          isLate
        ) {
          columns.push({
            text: "",
            style: {
              font: "LineAwesome",
              color: accent.redDark,
              alignment: "center",
              fontSize: 18,
            },
            width: 17,
          });
        }

        const isPlannedImplementationLate = recommendation
          ? RecommendationUtils.isPlannedImplementationLate(recommendation)
          : false;

        if (isPlannedImplementationLate) {
          columns.push({
            text: "",
            style: {
              font: "LineAwesome",
              color: accent.redDark,
              alignment: "center",
              fontSize: 18,
            },
            width: 17,
          });
        }

        return {
          columns,
          columnGap: 5,
        };
      },
    },

    onRender: (row) => {
      if (!row) return null;
      return <StatusIndicators rec={row} />;
    },
  },
  {
    key: "lastAction",
    fieldName: "lastAction",
    isResizable: true,

    labelKey: "ram.recommendations.column.lastAction.label",
    name: "ram.recommendations.column.lastAction.label",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    maxWidth: 100,
    width: 100,
    minWidth: 100,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "LastAction",
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      width: 500,
      render: ({ recommendation }) => {
        return {
          text: recommendation.lastAction,
          style: {
            bold: true,
            alignment: "left",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TooltipWithContentCell
          value={
            row?.lastAction === "A"
              ? "ram.riskAssessment.label"
              : row?.lastAction === "R"
              ? "ram.clientResponse.label"
              : row?.lastAction === "E"
              ? "ram.insurerEvaluation.label"
              : null
          }
        >
          <Icon
            iconName={
              row?.lastAction === "A"
                ? "CommentUrgent"
                : row?.lastAction === "R"
                ? "CannedChat"
                : row?.lastAction === "E"
                ? "Feedback"
                : null
            }
            style={{
              fontSize: "18px",
              cursor: "default",
            }}
          />
        </TooltipWithContentCell>
      );
    },
  },
  {
    key: "lastActionInsurer",
    fieldName: "lastActionInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.lastAction.label",
    name: "ram.recommendations.column.lastAction.label",
    isVisible: false,
    isSortable: true,
    minWidth: 100,
    maxWidth: 100,
    width: 100,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "LastAction",
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      width: 500,
      render: ({ recommendation }) => {
        return {
          text: recommendation.lastActionInsurer,
          style: {
            bold: true,
            alignment: "left",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TooltipWithContentCell
          value={
            row?.lastActionInsurer === "A"
              ? "ram.riskAssessment.label"
              : row?.lastActionInsurer === "R"
              ? "ram.clientResponse.label"
              : row?.lastActionInsurer === "E"
              ? "ram.insurerEvaluation.label"
              : null
          }
        >
          <Icon
            iconName={
              row?.lastActionInsurer === "A"
                ? "CommentUrgent"
                : row?.lastActionInsurer === "R"
                ? "CannedChat"
                : row?.lastActionInsurer === "E"
                ? "Feedback"
                : null
            }
            style={{
              fontSize: "18px",
              cursor: "default",
            }}
          />
        </TooltipWithContentCell>
      );
    },
  },
  {
    key: "dateOfLastResponse",
    fieldName: "dateOfLastResponse",
    isResizable: true,
    labelKey: "ram.recommendations.column.dateOfLastResponse.label",
    name: "ram.recommendations.column.dateOfLastResponse.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.dateOfLastResponse
            ? formatDateString(recommendation?.dateOfLastResponse)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.dateOfLastResponse
          ? formatDateString(recommendation?.dateOfLastResponse)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.dateOfLastResponse
        ? formatDateString(row?.dateOfLastResponse)
        : "";
    },
  },
  {
    key: "commentOfLastResponse",
    fieldName: "commentOfLastResponse",
    isResizable: true,
    labelKey: "ram.recommendations.column.commentOfLastResponse.label",
    name: "ram.recommendations.column.commentOfLastResponse.label",
    isVisible: false,
    isFixed: false,
    isSortable: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    pdf: {
      width: 200,
      render: ({ t, taxonomy, recommendation }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastResponse,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return { text: "" };
        return {
          text: doc.documentElement.textContent,
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastResponse,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return "";
        return doc.documentElement.textContent;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        row.commentOfLastResponse,
        "text/html"
      );
      if (doc.documentElement.textContent === "null") return "";
      return (
        <TooltipComponent
          content={doc.documentElement.textContent}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {doc.documentElement.textContent}
        </TooltipComponent>
      );
    },
  },
  {
    key: "numberOfResponses",
    fieldName: "numberOfResponses",
    isResizable: true,
    labelKey: "ram.recommendations.column.numberOfResponses.label",
    name: "ram.recommendations.column.numberOfResponses.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    filter: {
      type: "number",
      operator: "gt",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      min: 0,
      max: 1000,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.numberOfResponses;
    },
  },

  {
    key: "dateOfLastResponseInsurer",
    fieldName: "dateOfLastResponseInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.dateOfLastResponse.label",
    name: "ram.recommendations.column.dateOfLastResponse.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.dateOfLastResponseInsurer
            ? formatDateString(recommendation?.dateOfLastResponseInsurer)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.dateOfLastResponseInsurer
          ? formatDateString(recommendation?.dateOfLastResponseInsurer)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.dateOfLastResponseInsurer
        ? formatDateString(row?.dateOfLastResponseInsurer)
        : "";
    },
  },
  {
    key: "commentOfLastResponseInsurer",
    fieldName: "commentOfLastResponseInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.commentOfLastResponse.label",
    name: "ram.recommendations.column.commentOfLastResponse.label",
    isVisible: false,
    isFixed: false,
    isSortable: false,
    width: 200,
    pdf: {
      width: 200,
      render: ({ t, taxonomy, recommendation }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastResponseInsurer,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return { text: "" };
        return {
          text: doc.documentElement.textContent,
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastResponseInsurer,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return "";
        return doc.documentElement.textContent;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        row.commentOfLastResponseInsurer,
        "text/html"
      );
      if (doc.documentElement.textContent === "null") return "";
      return (
        <TooltipComponent
          content={doc.documentElement.textContent}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {doc.documentElement.textContent}
        </TooltipComponent>
      );
    },
  },
  {
    key: "numberOfResponsesInsurer",
    fieldName: "numberOfResponsesInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.numberOfResponses.label",
    name: "ram.recommendations.column.numberOfResponses.label",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    filter: {
      type: "number",
      operator: "gt",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      min: 0,
      max: 1000,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.numberOfResponsesInsurer;
    },
  },
  {
    key: "hasAssessmentAttachments",
    fieldName: "hasAssessmentAttachments",
    isResizable: true,
    labelKey: "ram.hasAssessmentAttachments",
    name: "ram.hasAssessmentAttachments",
    isVisible: false,
    isFixed: false,
    minWidth: 150,
    maxWidth: 150,
    width: 150,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
      isFiltered: (f) => f.value !== null,
    },
    pdf: {
      render: ({ recommendation }) => {
        if (recommendation.hasAssessmentAttachments) {
          return {
            text: "",
            style: { font: "Remix", fontSize: 18, color: accent.greenDark },
            alignment: "center",
          };
        }
        return {
          text: "",
        };
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        row?.hasAssessmentAttachments && (
          <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
        )
      );
    },
  },
  {
    key: "dateOfLastAssessment",
    fieldName: "dateOfLastAssessment",
    isResizable: true,
    labelKey: "ram.recommendations.column.dateOfLastAssessment.label",
    name: "ram.recommendations.column.dateOfLastAssessment.label",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.dateOfLastAssessment
            ? formatDateString(recommendation?.dateOfLastAssessment)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.dateOfLastAssessment
          ? formatDateString(recommendation?.dateOfLastAssessment)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.dateOfLastAssessment
        ? formatDateString(row?.dateOfLastAssessment)
        : "";
    },
  },
  {
    key: "commentOfLastAssessment",
    fieldName: "commentOfLastAssessment",
    isResizable: true,
    labelKey: "ram.recommendations.column.commentOfLastAssessment.label",
    name: "ram.recommendations.column.commentOfLastAssessment.label",
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    isSortable: false,

    pdf: {
      width: 200,
      render: ({ t, taxonomy, recommendation }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastAssessment,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return { text: "" };
        return {
          text: doc.documentElement.textContent,
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastAssessment,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return "";
        return doc.documentElement.textContent;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        row.commentOfLastAssessment,
        "text/html"
      );
      if (doc.documentElement.textContent === "null") return "";
      return (
        <TooltipComponent
          content={doc.documentElement.textContent}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {doc.documentElement.textContent}
        </TooltipComponent>
      );
    },
  },
  {
    key: "numberOfAssessments",
    fieldName: "numberOfAssessments",
    isResizable: true,
    labelKey: "ram.recommendations.column.numberOfAssessments.label",
    name: "ram.recommendations.column.numberOfAssessments.label",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    filter: {
      type: "number",
      operator: "gt",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      min: 0,
      max: 1000,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.numberOfAssessments;
    },
  },

  {
    key: "dateOfLastAssessmentInsurer",
    fieldName: "dateOfLastAssessmentInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.dateOfLastAssessment.label",
    name: "ram.recommendations.column.dateOfLastAssessment.label",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    width: 200,
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.dateOfLastAssessmentInsurer
            ? formatDateString(recommendation?.dateOfLastAssessmentInsurer)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.dateOfLastAssessmentInsurer
          ? formatDateString(recommendation?.dateOfLastAssessmentInsurer)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.dateOfLastAssessmentInsurer
        ? formatDateString(row?.dateOfLastAssessmentInsurer)
        : "";
    },
  },
  {
    key: "commentOfLastAssessmentInsurer",
    fieldName: "commentOfLastAssessmentInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.commentOfLastAssessment.label",
    name: "ram.recommendations.column.commentOfLastAssessment.label",
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    isSortable: false,
    pdf: {
      width: 200,
      render: ({ t, taxonomy, recommendation }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastAssessmentInsurer,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return { text: "" };
        return {
          text: doc.documentElement.textContent,
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastAssessmentInsurer,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return "";
        return doc.documentElement.textContent;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        row.commentOfLastAssessmentInsurer,
        "text/html"
      );
      if (doc.documentElement.textContent === "null") return "";
      return (
        <TooltipComponent
          content={doc.documentElement.textContent}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {doc.documentElement.textContent}
        </TooltipComponent>
      );
    },
  },
  {
    key: "numberOfAssessmentsInsurer",
    fieldName: "numberOfAssessmentsInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.numberOfAssessments.label",
    name: "ram.recommendations.column.numberOfAssessments.label",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    filter: {
      type: "number",
      operator: "gt",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      min: 0,
      max: 1000,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.numberOfAssessmentsInsurer;
    },
  },
  {
    key: "dateOfLastEvaluation",
    fieldName: "dateOfLastEvaluation",
    isResizable: true,
    labelKey: "ram.recommendations.column.dateOfLastEvaluation.label",
    name: "ram.recommendations.column.dateOfLastEvaluation.label",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.dateOfLastEvaluation
            ? formatDateString(recommendation?.dateOfLastEvaluation)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.dateOfLastEvaluation
          ? formatDateString(recommendation?.dateOfLastEvaluation)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.dateOfLastEvaluation
        ? formatDateString(row?.dateOfLastEvaluation)
        : "";
    },
  },
  {
    key: "commentOfLastEvaluation",
    fieldName: "commentOfLastEvaluation",
    isResizable: true,
    labelKey: "ram.recommendations.column.commentOfLastEvaluation.label",
    name: "ram.recommendations.column.commentOfLastEvaluation.label",
    isVisible: false,
    isSortable: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    pdf: {
      width: 200,
      render: ({ t, taxonomy, recommendation }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastEvaluation,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return { text: "" };
        return {
          text: doc.documentElement.textContent,
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.commentOfLastEvaluation,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return "";
        return doc.documentElement.textContent;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        row.commentOfLastEvaluation,
        "text/html"
      );
      if (doc.documentElement.textContent === "null") return "";
      return (
        <TooltipComponent
          content={doc.documentElement.textContent}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {doc.documentElement.textContent}
        </TooltipComponent>
      );
    },
  },
  {
    key: "numberOfEvaluations",
    fieldName: "numberOfEvaluations",
    isResizable: true,
    labelKey: "ram.recommendations.column.numberOfEvaluations.label",
    name: "ram.recommendations.column.numberOfEvaluations.label",
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    filter: {
      type: "number",
      operator: "gt",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      min: 0,
      max: 1000,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.numberOfEvaluations;
    },
  },
  {
    key: "clientName",
    fieldName: "clientName",
    isResizable: true,
    labelKey: "ram.clientsList.clientName",
    name: "ram.clientsList.clientName",
    isVisible: true,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: store.getState().clients.entities[
            recommendation?.webBasePartnerNumber
          ]?.clientName,
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return store.getState().clients.entities[
          recommendation?.webBasePartnerNumber
        ]?.clientName;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return store.getState().clients.entities[row?.webBasePartnerNumber]
        ?.clientName;
    },
  },
  {
    key: "locationName",
    fieldName: "locationName",
    isResizable: true,
    labelKey: "ram.locationList.locationName",
    name: "ram.locationList.locationName",
    isVisible: true,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    isSortable: true,
    iconName: "Sort",
    pdf: {
      width: 300,
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TooltipComponent
          content={row?.locationName}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {row?.locationName}
        </TooltipComponent>
      );
    },
  },
  {
    key: "surveyDate",
    fieldName: "surveyDate",
    isResizable: true,
    labelKey: "ram.recommendations.column.surveyDate.label",
    name: "ram.recommendations.column.surveyDate.label",
    isVisible: true,
    minWidth: 150,
    maxWidth: 150,
    width: 150,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.surveyDate
            ? formatDateString(recommendation?.surveyDate)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.surveyDate
          ? formatDateString(recommendation?.surveyDate)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.surveyDate ? formatDateString(row?.surveyDate) : "";
    },
  },
  {
    key: "recommendationNumberInternal",
    fieldName: "recommendationNumberInternal",
    isResizable: true,
    labelKey: "ram.recommendations.column.recommendationNumberInternal.label",
    name: "ram.recommendations.column.recommendationNumberInternal.label",
    isVisible: true,
    minWidth: 150,
    iconName: "Sort",
    isSortable: true,
    maxWidth: 150,
    width: 150,
    filter: {
      type: "text",
      value: "",
      isFiltered: isFilteredText,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.recommendationNumberInternal;
    },
  },
  {
    key: "recommendationNumberExternal",
    fieldName: "recommendationNumberExternal",
    isResizable: true,
    labelKey: "ram.recommendations.column.recommendationNumberExternal.label",
    name: "ram.recommendations.column.recommendationNumberExternal.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: true,
    minWidth: 150,
    maxWidth: 150,
    width: 150,
    filter: {
      type: "text",
      value: "",
      isFiltered: isFilteredText,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.recommendationNumberExternal;
    },
  },
  {
    key: "reportCreatorCode",
    fieldName: "reportCreatorCode",
    isResizable: true,
    labelKey: "ram.recommendations.column.reportCreatorCode.label",
    name: "ram.recommendations.column.reportCreatorCode.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: true,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      taxonomyKey: "RiskReportCreator",
      value: [],
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      render: ({ recommendation, taxonomy, t }) => {
        return {
          text: t(
            taxonomy.RiskReportCreator.byId[recommendation?.reportCreatorCode]
              ?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskReportCreator.byId[recommendation.reportCreatorCode]
            ?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskReportCreator"
          value={row?.reportCreatorCode}
        />
      );
    },
  },
  {
    key: "statusCompanyCode",
    fieldName: "statusCompanyCode",
    isResizable: true,
    labelKey: "ram.recommendations.column.statusCompanyCode.label",
    name: "ram.recommendations.column.statusCompanyCode.label",
    isSortable: true,
    iconName: "Sort",
    isVisible: true,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      taxonomyKey: "RiskImplementationStatus",
      value: [],
      isFiltered: isFilteredTaxonomy,
      exclude: [
        "RiskImplementationStatus.DONE_CHECKED",
        "RiskImplementationStatus.HELD_IN_ABEYENCE",
      ],
    },
    pdf: {
      render: ({ recommendation, taxonomy, t }) => {
        return {
          text: t(
            taxonomy.RiskImplementationStatus.byId[
              recommendation?.statusCompanyCode
            ]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskImplementationStatus.byId[
            recommendation.statusCompanyCode
          ]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskImplementationStatus"
          value={row?.statusCompanyCode}
        />
      );
    },
  },
  {
    key: "priorityCode",
    fieldName: "priorityCode",
    isResizable: true,
    labelKey: "ram.recommendations.column.priorityCode.label",
    name: "ram.recommendations.column.priorityCode.label",
    iconName: "Sort",
    isSortable: true,

    isVisible: true,
    minWidth: 150,
    maxWidth: 150,
    width: 150,
    filter: {
      type: "taxonomy",
      taxonomyKey: "RiskPriority",
      value: [],
      isFiltered: isFilteredTaxonomy,
      exclude: [
        "RiskPriority.NO_PRIORITY_DEFINED_BY_GREG",
        "RiskPriority.NO_PRIORITY_DEFINED_BY_INSURANCE_COMPANY",
      ],
    },
    pdf: {
      render: ({ recommendation, taxonomy, t }) => {
        return {
          text: t(
            taxonomy.RiskPriority.byId[recommendation?.priorityCode]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskPriority.byId[recommendation.priorityCode]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskPriority"
          value={row?.priorityCode}
        />
      );
    },
  },
  {
    key: "typeCode",
    fieldName: "typeCode",
    isResizable: true,
    labelKey: "ram.recommendations.column.typeCode.label",
    name: "ram.recommendations.column.typeCode.label",
    iconName: "Sort",
    isVisible: true,
    isSortable: true,
    minWidth: 150,
    maxWidth: 150,
    width: 150,
    filter: {
      type: "taxonomy",
      taxonomyKey: "RiskCategoryOfMeasure",
      value: [],
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      render: ({ recommendation, taxonomy, t }) => {
        return {
          text: t(
            taxonomy.RiskCategoryOfMeasure.byId[recommendation?.typeCode]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskCategoryOfMeasure.byId[recommendation.typeCode]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskCategoryOfMeasure"
          value={row?.typeCode}
        />
      );
    },
  },
  {
    key: "implementationDateInsurer",
    fieldName: "implementationDateInsurer",
    isResizable: true,
    labelKey: "ram.recommendations.column.implementationDateInsurer.label",
    name: "ram.recommendations.column.implementationDateInsurer.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: true,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation, taxonomy, t }) => {
        return {
          text: recommendation?.implementationDateInsurer
            ? formatDateString(recommendation?.implementationDateInsurer)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.implementationDateInsurer
          ? formatDateString(recommendation?.implementationDateInsurer)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.implementationDateInsurer
        ? formatDateString(row?.implementationDateInsurer)
        : "";
    },
  },
  {
    key: "mainCategoryCode",
    fieldName: "mainCategoryCode",
    isResizable: true,
    labelKey: "ram.recommendations.column.mainCategoryCode.label",
    name: "ram.recommendations.column.mainCategoryCode.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: true,
    minWidth: 180,
    maxWidth: 180,
    width: 180,
    filter: {
      type: "taxonomy",
      taxonomyKey: "RiskAssessmentCategory",
      value: [],
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      render: ({ recommendation, taxonomy, t }) => {
        return {
          text: t(
            taxonomy.RiskAssessmentCategory.byId[
              recommendation?.mainCategoryCode
            ]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskAssessmentCategory.byId[recommendation.mainCategoryCode]
            ?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskAssessmentCategory"
          value={row?.mainCategoryCode}
        />
      );
    },
  },
  {
    key: "weighting",
    fieldName: "weighting",
    isResizable: true,
    labelKey: "ram.recommendations.column.weighting.label",
    name: "ram.recommendations.column.weighting.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: true,
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    filter: {
      type: "number",
      operator: "gt",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      min: 1,
      max: 10,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.weighting;
    },
  },
  {
    key: "result",
    fieldName: "result",
    isResizable: true,
    labelKey: "ram.recommendations.column.result.label",
    name: "ram.recommendations.column.result.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: true,
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    filter: {
      type: "number",
      operator: "gt",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      min: 0,
      max: 20,
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.result;
    },
  },
  {
    key: "objectOrInfrastructure",
    fieldName: "objectOrInfrastructure",
    isResizable: true,
    labelKey: "ram.recommendationForm.objectOrInfrastructure.label",
    name: "ram.recommendationForm.objectOrInfrastructure.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    onRender: (row) => {
      if (!row) return null;
      return row?.objectOrInfrastructure;
    },
  },
  {
    key: "description",
    fieldName: "description",
    isResizable: true,
    labelKey: "ram.recommendationForm.description.label",
    name: "ram.recommendationForm.description.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "text",
      value: "",
      isFiltered: isFilteredText,
    },
    placeholder: "ram.columnOptions.typeToStartSearching",
    onRender: (row) => {
      if (!row) return null;
      return row?.description;
    },
  },
  {
    key: "statusGrECoCode",
    fieldName: "statusGrECoCode",
    isResizable: true,
    labelKey: "ram.recommendationForm.statusGrECoCode.label",
    name: "ram.recommendationForm.statusGrECoCode.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "RiskImplementationStatus",
      isFiltered: isFilteredTaxonomy,
      exclude: [],
    },
    pdf: {
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(
            taxonomy.RiskImplementationStatus.byId[
              recommendation?.statusGrECoCode
            ]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskImplementationStatus.byId[recommendation.statusGrECoCode]
            ?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskImplementationStatus"
          value={row?.statusGrECoCode}
        />
      );
    },
  },
  {
    key: "priorityCompanyCode",
    fieldName: "priorityCompanyCode",
    isResizable: true,
    labelKey: "ram.recommendationForm.priorityCompanyCode.label",
    name: "ram.recommendationForm.priorityCompanyCode.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "RiskPriority",
      isFiltered: isFilteredTaxonomy,
      exclude: [
        "RiskPriority.NO_PRIORITY_DEFINED_BY_GREG",
        "RiskPriority.NO_PRIORITY_DEFINED_BY_INSURANCE_COMPANY",
      ],
    },
    pdf: {
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(
            taxonomy.RiskPriority.byId[recommendation?.priorityCompanyCode]
              ?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskPriority.byId[recommendation.priorityCompanyCode]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskPriority"
          value={row?.priorityCompanyCode}
        />
      );
    },
  },
  {
    key: "implemented",
    fieldName: "implemented",
    isResizable: true,
    labelKey: "ram.recommendationForm.implemented.label",
    name: "ram.recommendationForm.implemented.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "date",
      operator: "isAfter",
      date1: null,
      date2: null,
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.implemented
            ? formatDateString(recommendation?.implemented)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.implemented
          ? formatDateString(recommendation?.implemented)
          : "";
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.implemented ? formatDateString(row?.implemented) : null;
    },
  },
  {
    key: "subCategory1Code",
    fieldName: "subCategory1Code",
    isResizable: true,
    labelKey: "ram.recommendationForm.subCategory1Code.label",
    name: "ram.recommendationForm.subCategory1Code.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "RiskAssessmentSubcategory",
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(
            taxonomy.RiskAssessmentSubcategory.byId[
              recommendation?.subCategory1Code
            ]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskAssessmentSubcategory.byId[
            recommendation.subCategory1Code
          ]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskAssessmentSubcategory"
          value={row?.subCategory1Code}
        />
      );
    },
  },
  {
    key: "subCategory2Code",
    fieldName: "subCategory2Code",
    isResizable: true,
    labelKey: "ram.recommendationForm.subCategory2Code.label",
    name: "ram.recommendationForm.subCategory2Code.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "RiskAssessmentSubcategory",
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(
            taxonomy.RiskAssessmentSubcategory.byId[
              recommendation?.subCategory2Code
            ]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskAssessmentSubcategory.byId[
            recommendation.subCategory2Code
          ]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskAssessmentSubcategory"
          value={row?.subCategory2Code}
        />
      );
    },
  },
  {
    key: "subCategory3Code",
    fieldName: "subCategory3Code",
    isResizable: true,
    labelKey: "ram.recommendationForm.subCategory3Code.label",
    name: "ram.recommendationForm.subCategory3Code.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "RiskAssessmentSubcategory",
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(
            taxonomy.RiskAssessmentSubcategory.byId[
              recommendation?.subCategory3Code
            ]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskAssessmentSubcategory.byId[
            recommendation.subCategory3Code
          ]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskAssessmentSubcategory"
          value={row?.subCategory3Code}
        />
      );
    },
  },
  {
    key: "improvementPotentialCode",
    fieldName: "improvementPotentialCode",
    isResizable: true,
    labelKey: "ram.recommendationForm.improvementPotentialCode.label",
    name: "ram.recommendationForm.improvementPotentialCode.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "RiskImprovementPotential",
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(
            taxonomy.RiskImprovementPotential.byId[
              recommendation?.improvementPotentialCode
            ]?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskImprovementPotential.byId[
            recommendation.improvementPotentialCode
          ]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskImprovementPotential"
          value={row?.improvementPotentialCode}
        />
      );
    },
  },
  {
    key: "noGo",
    fieldName: "noGo",
    isResizable: true,
    labelKey: "greco.required",
    name: "greco.required",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "boolean",
      value: null,
      isFiltered: (f) => f.value !== null,
    },
    pdf: {
      width: 100,
      render: ({ recommendation }) => {
        if (recommendation.noGo) {
          return {
            text: "",
            style: { font: "Remix", fontSize: 18, color: accent.greenDark },
            alignment: "center",
          };
        }
        return {
          text: "",
        };
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        row?.noGo && <Icon iconName="SkypeCheck" style={{ color: "#539e53" }} />
      );
    },
  },
  {
    key: "emlReductionPercent",
    fieldName: "emlReductionPercent",
    isResizable: true,
    labelKey: "ram.recommendationForm.emlReductionPercent.label",
    name: "ram.recommendationForm.emlReductionPercent.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      operator: "gt",
      min: 0,
      max: 100,
    },
    pdf: {
      render: ({ recommendation }) => {
        if (recommendation.emlReductionPercent === null) {
          return {
            text: "",
          };
        }
        return {
          text: `${recommendation.emlReductionPercent}%`,
        };
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return row?.emlReductionPercent && `${row?.emlReductionPercent}%`;
    },
  },
  {
    key: "implementationCostsEUR",
    fieldName: "implementationCostsEUR",
    isResizable: true,
    labelKey: "ram.recommendationForm.implementationCostsEUR.label",
    name: "ram.recommendationForm.implementationCostsEUR.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      operator: "gt",
    },
    pdf: {
      render: ({ recommendation }) => {
        const implementationCostsEUR = recommendation.implementationCostsEUR;
        if (implementationCostsEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(implementationCostsEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const implementationCostsEUR = row?.implementationCostsEUR;
      if (
        implementationCostsEUR !== null &&
        implementationCostsEUR !== undefined
      ) {
        return formatNumber(Number(implementationCostsEUR));
      }
    },
  },
  {
    key: "costBenifitFactor",
    fieldName: "costBenifitFactor",
    isResizable: true,
    labelKey: "ram.recommendationForm.costBenifitFactor.label",
    name: "ram.recommendationForm.costBenifitFactor.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      isFiltered: isFilteredNumber,
      operator: "gt",
    },
    pdf: {
      render: ({ recommendation }) => {
        const costBenifitFactor = recommendation.costBenifitFactor;
        if (costBenifitFactor === null)
          return {
            text: "",
          };
        return {
          text: Number(costBenifitFactor).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    excel: {
      render: ({ recommendation }) => {
        const costBenifitFactor = recommendation.costBenifitFactor;
        if (costBenifitFactor === null) return "";

        return Number(costBenifitFactor).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const costBenifitFactor = row?.costBenifitFactor;
      if (costBenifitFactor !== null && costBenifitFactor !== undefined) {
        return formatNumber(Number(costBenifitFactor));
      }
    },
  },
  {
    key: "costEstimateCode",
    fieldName: "costEstimateCode",
    isResizable: true,
    labelKey: "ram.recommendationForm.costEstimateCode.label",
    name: "ram.recommendationForm.costEstimateCode.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      taxonomyKey: "RiskReductionCost",
      value: [],
      isFiltered: isFilteredTaxonomy,
    },
    pdf: {
      width: 150,
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(
            taxonomy.RiskReductionCost.byId[recommendation.costEstimateCode]
              ?.code
          ),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code =
          taxonomy.RiskReductionCost.byId[recommendation.costEstimateCode]
            ?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="RiskReductionCost"
          value={row?.costEstimateCode}
        />
      );
    },
  },
  {
    key: "openPointsAfterResponse",
    labelKey: "ram.responsesPage.openPointsAfterResponse",
    name: "ram.responsesPage.openPointsAfterResponse",
    isSortable: false,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    pdf: {
      width: 200,
      render: ({ t, taxonomy, recommendation }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.openPointsAfterResponse,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return { text: "" };
        return {
          text: doc.documentElement.textContent,
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          recommendation.openPointsAfterResponse,
          "text/html"
        );
        if (doc.documentElement.textContent === "null") return "";
        return doc.documentElement.textContent;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        row.openPointsAfterResponse,
        "text/html"
      );
      if (doc.documentElement.textContent === "null") return "";
      return (
        <TooltipComponent
          content={doc.documentElement.textContent}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {doc.documentElement.textContent}
        </TooltipComponent>
      );
    },
  },
  {
    key: "reportName",
    fieldName: "reportName",
    isResizable: true,
    labelKey: "ram.reportList.reportName",
    name: "ram.reportList.reportName",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    onRender: (row) => {
      if (!row) return null;
      return row?.reportName;
    },
  },
  {
    key: "webBasePartnerNumber",
    fieldName: "webBasePartnerNumber",
    isResizable: true,
    labelKey: "greco.webBasePartnerNumber",
    name: "greco.webBasePartnerNumber",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    onRender: (row) => {
      if (!row) return null;
      return row?.webBasePartnerNumber;
    },
  },
  {
    key: "countryCode",
    fieldName: "countryCode",
    isResizable: true,
    labelKey: "greco.country",
    name: "greco.country",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    placeholder: "ram.columnOptions.typeToStartSearching",
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    filter: {
      type: "taxonomy",
      taxonomyKey: "Country",
      value: [],
      isFiltered: isFilteredTaxonomy,
      isSelect: true,
    },
    pdf: {
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: t(taxonomy.Country.byId[recommendation.countryCode]?.code),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code = taxonomy.Country.byId[recommendation.countryCode]?.code;
        return t(code);
      },
    },
    onRender: (row) => {
      if (!row) return null;
      return (
        <TranslatedTaxonomyCell
          taxonomyItem="Country"
          value={row?.countryCode}
        />
      );
    },
  },
  {
    key: "SICCode",
    fieldName: "SICCode",
    isResizable: true,
    labelKey: "ram.locationForm.SICCode",
    name: "ram.locationForm.SICCode",
    placeholder: "ram.columnOptions.typeToStartSearching",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    width: 150,
    filter: {
      type: "taxonomy",
      taxonomyKey: "SicCode",
      value: [],
      isFiltered: isFilteredTaxonomy,
      isSelect: true,
      customOptionLabel: (tax) =>
        tax?.code.split(".")[1] +
        " " +
        i18n.t("SicCode." + tax?.code.split(".")[1]),
    },
    pdf: {
      width: 150,
      render: ({ t, taxonomy, recommendation }) => {
        return {
          text: transformSicCode({
            sicCode: recommendation?.SICCode,
            t,
            taxonomy,
          }),
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        const code = transformSicCode({
          sicCode: recommendation?.SICCode,
          t,
          taxonomy,
        });
        return code;
      },
    },
    onRender: (row, index) => {
      if (!row?.SICCode) return null;

      return (
        <>
          <TranslatedTaxonomyCell
            taxonomyItem="SicCode"
            value={row?.SICCode}
            showCodeNumber
          />
        </>
      );
    },
  },
  {
    key: "postalCode",
    fieldName: "postalCode",
    isResizable: true,
    labelKey: "ram.locationList.postalCode",
    name: "ram.locationList.postalCode",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    pdf: {
      width: 150,
    },
    onRender: (row) => {
      if (!row?.postalCode) return null;

      return row?.postalCode;
    },
  },
  {
    key: "city",
    fieldName: "city",
    isResizable: true,
    labelKey: "ram.locationList.city",
    name: "ram.locationList.city",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    onRender: (row) => {
      if (!row?.city) return null;

      return row?.city;
    },
  },
  {
    key: "coordinates",
    fieldName: "coordinates",
    isResizable: true,
    labelKey: "ram.locationList.coordinates",
    name: "ram.locationList.coordinates",
    isSortable: false,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    pdf: {
      width: 170,
      render: ({ recommendation }) => {
        if (!recommendation.latitude)
          return {
            text: "",
          };
        return {
          text: `${Number(recommendation.latitude).toFixed(4)} , ${Number(
            recommendation.longitude
          ).toFixed(4)}`,
          style: {
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({ recommendation }: { recommendation: RecommendationAzure }) => {
        if (!recommendation.latitude) return "";
        return `${Number(recommendation.latitude).toFixed(4)} , ${Number(
          recommendation.longitude
        ).toFixed(4)}`;
      },
    },
    onRender: (row) => {
      if (!row) return null;
      if (!row?.latitude) return null;

      return `${Number(row.latitude).toFixed(4)} , ${Number(
        row.longitude
      ).toFixed(4)}`;
    },
  },
  {
    key: "turnoverInMioEUR",
    fieldName: "turnoverInMioEUR",
    isResizable: true,
    labelKey: "ram.locationList.turnoverInMioEur",
    name: "ram.locationList.turnoverInMioEur",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    pdf: {
      render: ({ recommendation }) => {
        const turnoverInMioEUR = recommendation.turnoverInMioEUR;
        if (turnoverInMioEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(turnoverInMioEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row?.turnoverInMioEUR) return null;

      const turnoverInMioEUR = row?.turnoverInMioEUR;
      if (turnoverInMioEUR !== null && turnoverInMioEUR !== undefined) {
        return Number(turnoverInMioEUR).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "TSIInMioEUR",
    fieldName: "TSIInMioEUR",
    isResizable: true,
    labelKey: "ram.locationList.tsiinMioEur",
    name: "ram.locationList.tsiinMioEur",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    pdf: {
      render: ({ recommendation }) => {
        const TSIInMioEUR = recommendation.TSIInMioEUR;
        if (TSIInMioEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(TSIInMioEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row?.TSIInMioEUR) return null;

      const TSIInMioEUR = row?.TSIInMioEUR;
      if (TSIInMioEUR !== null && TSIInMioEUR !== undefined) {
        return Number(TSIInMioEUR).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "PMLInMioEUR",
    fieldName: "PMLInMioEUR",
    isResizable: true,
    labelKey: "ram.locationList.pmlinMioEur",
    name: "ram.locationList.pmlinMioEur",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    pdf: {
      render: ({ recommendation }) => {
        const PMLInMioEUR = recommendation.PMLInMioEUR;
        if (PMLInMioEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(PMLInMioEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row?.PMLInMioEUR) return null;

      const PMLInMioEUR = row?.PMLInMioEUR;
      if (PMLInMioEUR !== null && PMLInMioEUR !== undefined) {
        return Number(PMLInMioEUR).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "EMLInMioEUR",
    fieldName: "EMLInMioEUR",
    isResizable: true,
    labelKey: "ram.locationList.emlinMioEur",
    name: "ram.locationList.emlinMioEur",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    pdf: {
      render: ({ recommendation }) => {
        const EMLInMioEUR = recommendation.EMLInMioEUR;
        if (EMLInMioEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(EMLInMioEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row?.EMLInMioEUR) return null;

      const EMLInMioEUR = row?.EMLInMioEUR;
      if (EMLInMioEUR !== null && EMLInMioEUR !== undefined) {
        return Number(EMLInMioEUR).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "EBITInMioEUR",
    fieldName: "EBITInMioEUR",
    isResizable: true,
    labelKey: "ram.locationList.ebitinMioEur",
    name: "ram.locationList.ebitinMioEur",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    pdf: {
      render: ({ recommendation }) => {
        const EBITInMioEUR = recommendation.EBITInMioEUR;
        if (EBITInMioEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(EBITInMioEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row?.EBITInMioEUR) return null;

      const EBITInMioEUR = row?.EBITInMioEUR;
      if (EBITInMioEUR !== null && EBITInMioEUR !== undefined) {
        return Number(EBITInMioEUR).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "YGPCMInMioEUR",
    fieldName: "YGPCMInMioEUR",
    isResizable: true,
    labelKey: "ram.locationList.ygpcminMioEur",
    name: "ram.locationList.ygpcminMioEur",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    pdf: {
      render: ({ recommendation }) => {
        const YGPCMInMioEUR = recommendation.YGPCMInMioEUR;
        if (YGPCMInMioEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(YGPCMInMioEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row?.YGPCMInMioEUR) return null;

      const YGPCMInMioEUR = row?.YGPCMInMioEUR;
      if (YGPCMInMioEUR !== null && YGPCMInMioEUR !== undefined) {
        return Number(YGPCMInMioEUR).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "deductiblesInMioEUR",
    fieldName: "deductiblesInMioEUR",
    isResizable: true,
    labelKey: "ram.locationList.deductiblesinMioEur",
    name: "ram.locationList.deductiblesinMioEur",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    pdf: {
      render: ({ recommendation }) => {
        const deductiblesInMioEUR = recommendation.deductiblesInMioEUR;
        if (deductiblesInMioEUR === null)
          return {
            text: "",
          };
        return {
          text: Number(deductiblesInMioEUR).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      },
    },
    onRender: (row) => {
      if (!row?.deductiblesInMioEUR) return null;

      const deductiblesInMioEUR = row?.deductiblesInMioEUR;
      if (deductiblesInMioEUR !== null && deductiblesInMioEUR !== undefined) {
        return Number(deductiblesInMioEUR).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "insurersRaiting",
    fieldName: "insurersRaiting",
    isResizable: true,
    labelKey: "ram.locationList.insurersRating",
    name: "ram.locationList.insurersRating",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    onRender: (row) => {
      if (!row?.insurersRaiting) return null;

      return row?.insurersRaiting;
    },
  },
  {
    key: "assessmentPeriodStartedAt",
    fieldName: "assessmentPeriodStartedAt",
    isResizable: true,
    labelKey: "ram.assessmentPeriodStartedAt",
    name: "ram.assessmentPeriodStartedAt",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.assessmentPeriodStartedAt
            ? formatDateString(recommendation?.assessmentPeriodStartedAt)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.assessmentPeriodStartedAt
          ? formatDateString(recommendation?.assessmentPeriodStartedAt)
          : "";
      },
    },
    onRender: (row) => {
      if (!row?.assessmentPeriodStartedAt) return null;
      return (
        row?.assessmentPeriodStartedAt &&
        formatDateString(row?.assessmentPeriodStartedAt)
      );
    },
  },
  {
    key: "plannedImplementationDate",
    fieldName: "plannedImplementationDate",
    isResizable: true,
    labelKey: "ram.recommendations.column.implementationDate.label",
    name: "ram.recommendations.column.implementationDate.label",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 200,
    width: 200,
    minWidth: 200,
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
      isFiltered: isFilteredDate,
    },
    pdf: {
      render: ({ recommendation }) => {
        return {
          text: recommendation?.plannedImplementationDate
            ? formatDateString(recommendation?.plannedImplementationDate)
            : "",
        };
      },
    },
    excel: {
      render: ({
        recommendation,
        taxonomy,
        t,
      }: {
        recommendation: RecommendationAzure;
        taxonomy: TaxonomyState;
        t: TFunction;
      }) => {
        return recommendation?.plannedImplementationDate
          ? formatDateString(recommendation?.plannedImplementationDate)
          : "";
      },
    },
    onRender: (row) => {
      if (!row?.plannedImplementationDate) return null;
      return (
        row?.plannedImplementationDate &&
        formatDateString(row?.plannedImplementationDate)
      );
    },
  },
  {
    key: "isAssessed",
    fieldName: "isAssessed",
    isResizable: true,
    labelKey: "ram.assessed",
    name: "ram.assessed",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 100,
    width: 100,
    minWidth: 100,
    filter: {
      type: "boolean",
      value: null,
      isFiltered: (f) => f.value !== null,
    },
    pdf: {
      render: ({ recommendation }) => {
        if (recommendation.isAssessed) {
          return {
            text: "",
            style: { font: "Remix", fontSize: 18, color: accent.greenDark },
            alignment: "center",
          };
        }
        return {
          text: "",
        };
      },
    },
    onRender: (row) => {
      if (!row?.isAssessed) return null;
      return (
        row?.isAssessed && (
          <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
        )
      );
    },
  },
  {
    key: "hasRecommendationAttachments",
    fieldName: "hasRecommendationAttachments",
    isResizable: true,
    labelKey: "ram.hasRecommendationAttachments",
    name: "ram.hasRecommendationAttachments",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    filter: {
      type: "boolean",
      value: null,
      isFiltered: (f) => f.value !== null,
    },
    pdf: {
      render: ({ recommendation }) => {
        if (recommendation.hasRecommendationAttachments) {
          return {
            text: "",
            style: { font: "Remix", fontSize: 18, color: accent.greenDark },
            alignment: "center",
          };
        }
        return {
          text: "",
        };
      },
    },
    onRender: (row) => {
      if (!row?.hasRecommendationAttachments) return null;
      return (
        row?.hasRecommendationAttachments && (
          <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
        )
      );
    },
  },
  /////
  {
    key: "hasResponseAttachments",
    fieldName: "hasResponseAttachments",
    isResizable: true,
    labelKey: "ram.hasResponseAttachments",
    name: "ram.hasResponseAttachments",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    filter: {
      type: "boolean",
      value: null,
      isFiltered: (f) => f.value !== null,
    },
    pdf: {
      render: ({ recommendation }) => {
        if (recommendation.hasResponseAttachments) {
          return {
            text: "",
            style: { font: "Remix", fontSize: 18, color: accent.greenDark },
            alignment: "center",
          };
        }
        return {
          text: "",
        };
      },
    },
    onRender: (row) => {
      if (!row?.hasResponseAttachments) return null;
      return (
        row?.hasResponseAttachments && (
          <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
        )
      );
    },
  },

  {
    key: "hasEvaluationAttachments",
    fieldName: "hasEvaluationAttachments",
    isResizable: true,
    labelKey: "ram.hasEvaluationAttachments",
    name: "ram.hasEvaluationAttachments",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    filter: {
      type: "boolean",
      value: null,
      isFiltered: (f) => f.value !== null,
    },
    pdf: {
      render: ({ recommendation }) => {
        if (recommendation.hasEvaluationAttachments) {
          return {
            text: "",
            style: { font: "Remix", fontSize: 18, color: accent.greenDark },
            alignment: "center",
          };
        }
        return {
          text: "",
        };
      },
    },
    onRender: (row) => {
      if (!row?.hasEvaluationAttachments) return null;
      return (
        row?.hasEvaluationAttachments && (
          <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
        )
      );
    },
  },
  {
    key: "hasDraftResponses",
    fieldName: "hasDraftResponses",
    isResizable: true,
    labelKey: "ram.hasDraftResponses",
    name: "ram.hasDraftResponses",
    iconName: "Sort",
    isSortable: true,
    isVisible: false,
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    filter: {
      type: "boolean",
      value: null,
      isFiltered: (f) => f.value !== null,
    },
    pdf: {
      render: ({ recommendation }) => {
        if (recommendation.hasDraftResponses) {
          return {
            text: "",
            style: { font: "Remix", fontSize: 18, color: accent.greenDark },
            alignment: "center",
          };
        }
        return {
          text: "",
        };
      },
    },
    onRender: (row) => {
      if (!row?.hasDraftResponses) return null;
      return (
        row?.hasDraftResponses && (
          <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />
        )
      );
    },
  },
];
