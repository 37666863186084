import Axios from "axios";
import i18next from "i18next";
import {
  call,
  cancelled,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as API from "../../api/api";
import { toast } from "../../components/FluentToast";
import { Recommendation } from "../../types/types";
import { createPDFRecommendations } from "../../utils/pdf";
import {
  loadRecommendationsActions,
  removeOne,
  setRecommendationsIds,
  updateRecommendation,
} from "../recommendations/recommendations";
import { setColumns } from "../recommendationsColumnOptions";
import { selectTaxonomy } from "../taxonomy/taxonomy";
import { handleAxiosError } from "../util";
import {
  deleteRecommendationRoutine,
  copyRecommendationRoutine,
  pasteRecommendationRoutine,
  exportPDFRecommendationsActions,
  notifyRecommendation,
  setColumnsRecommendations,
  setDeleteDialog,
  setCopyDialog,
  setPasteDialog,
  setSearchRecommendations,
  setSelectedViewIdRecommendations,
  setSortRecommendations,
  loadRecommendationsExcelActions,
} from "./recommendationsPage";
import { selectColumnsSelectedView, selectVisibleColumns } from "./selectors";

const TOP = 40;

export function* setSearchRecommendationsSaga() {
  yield takeEvery(setSearchRecommendations.type, function* () {
    yield put(
      loadRecommendationsActions.trigger({ top: TOP, skip: 0, reset: true })
    );
  });
}

export function* setSortRecommendationsSaga() {
  yield takeEvery(setSortRecommendations.type, function* () {
    yield put(
      loadRecommendationsActions.trigger({ top: TOP, skip: 0, reset: true })
    );
  });
}

export function* notifyRecommendationSaga() {
  yield takeEvery(
    notifyRecommendation.trigger,
    function* (a: ReturnType<typeof notifyRecommendation.trigger>) {
      const recommendation = a.payload;
      const taxonomy = selectTaxonomy(yield select());
      try {
        yield put(notifyRecommendation.loading(recommendation));
        const res = yield call(
          API.changeRecommendationStatus,
          recommendation.locationId,
          recommendation.reportId,
          recommendation.recommendationId,
          taxonomy.RiskRecommendationStatus.byCode[
            "RiskRecommendationStatus.WAITING_FOR_CLIENT"
          ].id
        );
        const notifedRec = res.data as Recommendation;
        yield put(notifyRecommendation.success(notifedRec));
        const originalIds = yield select((s) => s.recommendations.ids);
        yield put(
          updateRecommendation({
            id: notifedRec.recommendationId,
            changes: {
              recommendationStatusCode: notifedRec.recommendationStatusCode,
            },
          })
        );
        yield put(setRecommendationsIds(originalIds));
      } catch (err) {
        yield put(notifyRecommendation.error(err));
        handleAxiosError(err);
      }
    }
  );
}

function* exportPDFSaga() {
  yield takeEvery(exportPDFRecommendationsActions.trigger, function* () {
    const cancelSource = Axios.CancelToken.source();
    const columns = yield select((s) => s.recommendationsPage.columns);
    const visibleColumns = selectVisibleColumns(yield select());
    const actualColumns = visibleColumns.filter(
      (c) => c.key !== "options" && c.key !== "statusIndicators"
    );
    const taxonomy = yield select((s) => s.taxonomy);
    const clientsFilter = yield select((s) => s.filters.clientsFilter);
    const locationsFilter = yield select((s) => s.filters.locationsFilter);
    const reportsFilter = yield select((s) => s.filters.reportsFilter);
    const search = yield select((s) => s.recommendationsPage.search);
    const sort = yield select((s) => s.recommendationsPage.sort);

    try {
      yield put(exportPDFRecommendationsActions.loading());
      const res = yield call(API.searchRecommendations, {
        top: 1000000,
        skip: 0,
        cancelToken: cancelSource.token,
        clientsFilter,
        locationsFilter,
        reportsFilter,
        columns,
        search,
        sortDir: sort.dir,
        sortKey: sort.key,
      });
      const recommendations = res.data.value;
      createPDFRecommendations({
        recommendations,
        columns: actualColumns,
        taxonomy,
        t: i18next.t.bind(i18next),
      }).open();

      yield put(exportPDFRecommendationsActions.success());
    } catch (err) {
      console.log(err);
      yield put(exportPDFRecommendationsActions.error(err));
    } finally {
      if (yield cancelled()) {
        cancelSource.cancel();
      }
    }
  });
}

function* loadRecommendationsExcelSaga() {
  yield takeEvery(
    loadRecommendationsExcelActions.trigger,
    function* (a: ReturnType<typeof loadRecommendationsExcelActions.trigger>) {
      const { onSuccess, onError } = a.payload as any;
      yield delay(300);
      const cancelSource = Axios.CancelToken.source();
      const clientsFilter = yield select((s) => s.filters.clientsFilter);
      const locationsFilter = yield select((s) => s.filters.locationsFilter);
      const reportsFilter = yield select((s) => s.filters.reportsFilter);
      const search = yield select((s) => s.recommendationsPage.search);
      const sort = yield select((s) => s.recommendationsPage.sort);
      const columns = yield select((s) => s.recommendationsPage.columns);

      try {
        yield put(loadRecommendationsExcelActions.loading());
        const res = yield call(API.searchRecommendations, {
          top: 1000000,
          skip: 0,
          cancelToken: cancelSource.token,
          clientsFilter,
          locationsFilter,
          reportsFilter,
          columns,
          search,
          sortDir: sort.dir,
          sortKey: sort.key,
        });
        onSuccess && onSuccess((res as any).data);
        yield put(loadRecommendationsExcelActions.success());
      } catch (err) {
        yield put(loadRecommendationsExcelActions.error());
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* setColumnsRecommendationsSaga() {
  yield takeEvery(setColumnsRecommendations.type, function* () {
    yield put(
      loadRecommendationsActions.trigger({ top: TOP, skip: 0, reset: true })
    );
  });
}

function* setSelectedViewIdRecommendationsSaga() {
  yield takeEvery(setSelectedViewIdRecommendations.type, function* () {
    let columns = selectColumnsSelectedView(yield select());
    const userRole = yield select((s) => s.userRole.userRole);

    if (userRole === "insurer") {
      columns = columns.filter(
        (col) =>
          col.key !== "dateOfLastResponse" &&
          col.key !== "commentOfLastResponse" &&
          col.key !== "numberOfResponses" &&
          col.key !== "dateOfLastAssessment" &&
          col.key !== "commentOfLastAssessment" &&
          col.key !== "numberOfAssessments" &&
          col.key !== "lastAction"
      );
    } else {
      columns = columns.filter(
        (col) =>
          col.key !== "dateOfLastResponseInsurer" &&
          col.key !== "commentOfLastResponseInsurer" &&
          col.key !== "numberOfResponsesInsurer" &&
          col.key !== "dateOfLastAssessmentInsurer" &&
          col.key !== "commentOfLastAssessmentInsurer" &&
          col.key !== "numberOfAssessmentsInsurer" &&
          col.key !== "lastActionInsurer"
      );
    }
    yield put(setColumns(columns));
  });
}

export function* deleteRecommendationSaga() {
  yield takeEvery(deleteRecommendationRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId } = a.payload;
    try {
      yield put(deleteRecommendationRoutine.loading());
      const res = yield call(API.deleteRecommendation, {
        locationId,
        reportId,
        recommendationId,
      });
      yield put(deleteRecommendationRoutine.success(res.data));
      yield put(removeOne(recommendationId));
      yield put(
        loadRecommendationsActions.trigger({
          skip: 0,
          top: TOP,
          reset: true,
        })
      );
      toast.success(i18next.t("greco.success"));
      yield put(
        setDeleteDialog({
          isOpen: false,
          recommendation: null,
        })
      );
    } catch (err) {
      yield put(deleteRecommendationRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* copyRecommendationSaga() {
  yield takeEvery(copyRecommendationRoutine.trigger, function* (a) {
    const { recommendation } = a.payload;
    try {
      yield put(copyRecommendationRoutine.loading());
      yield put(copyRecommendationRoutine.success(recommendation));
      toast.success(i18next.t("greco.success"));
      yield put(
        setCopyDialog({
          isOpen: false,
          recommendation: null,
        })
      );
    } catch (err) {
      yield put(copyRecommendationRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* pasteRecommendationSaga() {
  yield takeEvery(pasteRecommendationRoutine.trigger, function* (a) {
    const { locationId, reportId, recommendationId, destinationReportIds } =
      a.payload;
    try {
      yield put(pasteRecommendationRoutine.loading());
      const res = yield call(API.duplicateRecommendation, {
        locationId,
        reportId,
        recommendationId,
        destinationReportIds,
      });

      toast.success(i18next.t("greco.success"));
      yield put(
        setPasteDialog({
          isOpen: false,
          report: null,
          recommendation: null,
        })
      );
      yield put(pasteRecommendationRoutine.success());
    } catch (err) {
      yield put(pasteRecommendationRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* recommendationsPageSaga() {
  yield fork(setSearchRecommendationsSaga);
  yield fork(setSortRecommendationsSaga);
  yield fork(notifyRecommendationSaga);
  yield fork(exportPDFSaga);
  yield fork(loadRecommendationsExcelSaga);
  yield fork(setColumnsRecommendationsSaga);
  yield fork(setSelectedViewIdRecommendationsSaga);
  yield fork(deleteRecommendationSaga);
  yield fork(copyRecommendationSaga);
  yield fork(pasteRecommendationSaga);
}
