import { format } from "date-fns";

export const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

export const formatDateString = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy");
};

export const formatDateStringWithTime = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy kk:mm:ss");
};
