import { IconButton, mergeStyleSets } from "@fluentui/react";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ComboBoxField from "../../../components/controls/ComboBoxField";
import TextInputField from "../../../components/controls/TextInputField";
import { useCurrentRoute } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import useGetTaxonomyOptions from "../../../utils/hooks/useGetTaxonomyOptions";
import { MapModalWithGeoSearch } from "../MapModalWithGeoSearch";
import { SicCodePicker } from "./SicCodePicker";

function LocationFields() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const route = useCurrentRoute();

  const isEdit = route === "editLocation";
  const { values, handleChange, errors, setFieldValue } =
    useFormikContext() as any;

  const defaultWeightings = useSelector(
    (s) => s.locationPage.defaultWeightings
  );

  const countryOptions = useGetTaxonomyOptions({
    taxonomyKey: "Country",
  });

  const defaultSubcategoryWeightingNameOptions = useMemo(() => {
    return defaultWeightings?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [defaultWeightings]);
  useEffect(() => {
    if (
      values?.locationName &&
      values?.address &&
      values?.address?.trim() !== values?.locationName?.trim()
    ) {
      // setFieldValue("city", null);
      // setFieldValue("postalCode", null);
      // setFieldValue("countryCode", null);
      setFieldValue("latitude", null);
      setFieldValue("longitude", null);
    }
  }, [values.locationName, values.address]);

  return (
    <>
      <div>
        <TextInputField
          value={values?.locationName}
          onChange={handleChange}
          errors={errors}
          required
          name={"locationName"}
          disabled={false}
          label={t("ram.locationForm.locationName.label")}
          onRenderSuffix={() => (
            <IconButton
              text={t("ram.locationForm.setLocationBtn.label")}
              iconProps={{ iconName: "MapPin" }}
              styles={{
                root: {
                  marginBottom: "5px",
                },
              }}
              onClick={() => setIsModalOpen(true)}
            />
          )}
        />
        <TextInputField
          value={values?.address}
          onChange={handleChange}
          errors={errors}
          required={false}
          name={"address"}
          disabled={false}
          label={t("ram.locationForm.address.label")}
        />
        <div className={classes.cordinatesRow}>
          <TextInputField
            value={values?.latitude?.toString()}
            onChange={handleChange}
            errors={errors}
            required={false}
            name={"latitude"}
            disabled={true}
            label={t("ram.locationForm.latitude.label")}
          />
          <TextInputField
            value={values?.longitude?.toString()}
            onChange={handleChange}
            errors={errors}
            required={false}
            name={"longitude"}
            disabled={true}
            label={t("ram.locationForm.longitude.label")}
          />
        </div>
        <TextInputField
          value={values?.city}
          onChange={handleChange}
          errors={errors}
          required
          name={"city"}
          disabled={false}
          label={t("ram.locationForm.city.label")}
        />
        <ComboBoxField
          disabled={false}
          errors={errors}
          name="countryCode"
          label={t("ram.locationForm.countryCode.label")}
          setFieldValue={setFieldValue}
          options={countryOptions || []}
          defaultValue={values?.countryCode}
          confidentiality={null}
          required
        />
        <TextInputField
          value={values?.postalCode}
          onChange={handleChange}
          errors={errors}
          required
          name={"postalCode"}
          disabled={false}
          label={t("ram.locationForm.postalCode.label")}
        />
        <SicCodePicker />
        {!isEdit ? (
          <ComboBoxField
            disabled={false}
            errors={errors}
            required
            name="defaultSubcategoryWeightingName"
            label={t("ram.locationForm.defaultSubcategoryWeightingName.label")}
            setFieldValue={setFieldValue}
            options={defaultSubcategoryWeightingNameOptions || []}
            defaultValue={values?.defaultSubcategoryWeightingName}
            confidentiality={null}
          />
        ) : null}
      </div>
      {isModalOpen && (
        <MapModalWithGeoSearch
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}

export default LocationFields;

const classes = mergeStyleSets({
  cordinatesRow: {
    display: "flex",

    justifyContent: "space-between",
    gap: 10,
    "& > *, .ms-TextField, .ms-TooltipHost, .ms-TextField-wrapper": {
      width: "100%",
    },
  },
});
