import { TooltipOverflowMode } from "@fluentui/react";
import React from "react";
import { CenteredCell } from "../../../components/styled";
import { store } from "../../../store/store";
import { formatNumber } from "../../../utils/number";
import { ContextButton } from "../ContextButton";
import { StatusCell } from "../StatusCell";
import CountryCode from "./CountryCode";
import FixedCell from "./FixedCell";
import SicCode from "./SicCode";
import { TooltipComponent } from "components/TooltipComponent";

export const defaultColumns = [
  {
    key: "assessmentPeriodStartedAt",
    fieldName: "assessmentPeriodStartedAt",
    name: "",
    labelKey: "",
    maxWidth: 40,
    minWidth: 40,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",

    onRender: (row, index) => {
      if (!row) return null;
      return (
        <FixedCell no={index} row={row}>
          <StatusCell loc={row} />
        </FixedCell>
      );
    },
  },
  {
    key: "locationName",
    fieldName: "locationName",
    labelKey: "ram.locationList.locationName",
    name: "ram.locationList.locationName",
    maxWidth: 250,
    minWidth: 250,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row, index) => {
      if (!row) return null;
      return (
        <TooltipComponent
          content={row?.locationName}
          overflowMode={TooltipOverflowMode.Parent}
          styles={{
            root: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              position: "relative",
              bottom: "11px",
              display: "flex",
              width: "270px",
              height: "42px",
            },
          }}
        >
          <FixedCell row={row} no={index}>
            {row?.locationName}
          </FixedCell>
        </TooltipComponent>
      );
    },
  },
  {
    key: "options",
    fieldName: "options",

    labelKey: "",
    name: "",
    minWidth: 40,
    maxWidth: 40,
    isVisible: true,
    isSortable: false,

    onRender: (row, index) => {
      if (!row) return null;
      return (
        <div
          style={{
            height: "41px",
            width: "60px",
            position: "relative",
            bottom: "11px",
          }}
        >
          <FixedCell no={index} row={row}>
            <CenteredCell>
              <ContextButton loc={row} />
            </CenteredCell>
          </FixedCell>
        </div>
      );
    },
  },
  {
    key: "clientName",
    fieldName: "clientName",
    labelKey: "ram.clientsList.clientName",
    name: "ram.clientsList.clientName",
    minWidth: 200,
    maxWidth: 200,
    isVisible: true,
    isResizable: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return null;
      return row?.clientName;
    },
  },
  {
    key: "siccode",
    fieldName: "siccode",
    labelKey: "ram.locationList.sicCode",
    name: "ram.locationList.sicCode",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      if (!row) return null;
      return <SicCode row={row} />;
    },
  },
  {
    key: "countryCode",
    fieldName: "countryCode",
    labelKey: "ram.locationList.countryCode",
    name: "ram.locationList.countryCode",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      if (!row) return null;
      return <CountryCode row={row} />;
    },
  },
  {
    key: "postalCode",
    fieldName: "postalCode",
    labelKey: "ram.locationList.postalCode",
    name: "ram.locationList.postalCode",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => row?.postalCode,
  },
  {
    key: "city",
    fieldName: "city",

    labelKey: "ram.locationList.city",
    name: "ram.locationList.city",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => row?.city,
  },
  {
    key: "coords",
    fieldName: "coords",
    labelKey: "ram.locationList.coordinates",
    name: "ram.locationList.coordinates",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: false,
    isResizable: true,

    onRender: (row) => {
      if (!row?.latitude) return null;

      return `${row?.latitude} , ${row?.longitude}`;
    },
  },
  {
    key: "turnoverInMioEur",
    fieldName: "turnoverInMioEur",
    labelKey: "ram.locationList.turnoverInMioEur",
    name: "ram.locationList.turnoverInMioEur",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      const turnoverInMioEur = row?.turnoverInMioEur;
      if (turnoverInMioEur !== null && turnoverInMioEur !== undefined) {
        return Number(turnoverInMioEur).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
  {
    key: "tsiinMioEur",
    fieldName: "tsiinMioEur",
    labelKey: "ram.locationList.tsiinMioEur",
    name: "ram.locationList.tsiinMioEur",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      const tsiinMioEur = row?.tsiinMioEur;
      if (tsiinMioEur !== null && tsiinMioEur !== undefined) {
        return formatNumber(Number(tsiinMioEur));
      }
    },
  },
  {
    key: "pmlinMioEur",
    fieldName: "pmlinMioEur",
    labelKey: "ram.locationList.pmlinMioEur",
    name: "ram.locationList.pmlinMioEur",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      const pmlinMioEur = row?.pmlinMioEur;
      if (pmlinMioEur !== null && pmlinMioEur !== undefined) {
        return formatNumber(Number(pmlinMioEur));
      }
    },
  },
  {
    key: "emlinMioEur",
    fieldName: "emlinMioEur",
    labelKey: "ram.locationList.emlinMioEur",
    name: "ram.locationList.emlinMioEur",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      const emlinMioEur = row?.emlinMioEur;
      if (emlinMioEur !== null && emlinMioEur !== undefined) {
        return formatNumber(Number(emlinMioEur));
      }
    },
  },
  {
    key: "ebitinMioEur",
    fieldName: "ebitinMioEur",
    labelKey: "ram.locationList.ebitinMioEur",
    name: "ram.locationList.ebitinMioEur",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      const ebitinMioEur = row?.ebitinMioEur;
      if (ebitinMioEur !== null && ebitinMioEur !== undefined) {
        return formatNumber(Number(ebitinMioEur));
      }
    },
  },
  {
    key: "ygpcminMioEur",
    fieldName: "ygpcminMioEur",
    labelKey: "ram.locationList.ygpcminMioEur",
    name: "ram.locationList.ygpcminMioEur",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      const ygpcminMioEur = row?.ygpcminMioEur;
      if (ygpcminMioEur !== null && ygpcminMioEur !== undefined) {
        return formatNumber(Number(ygpcminMioEur));
      }
    },
  },
  {
    key: "deductiblesInMioEur",
    fieldName: "deductiblesInMioEur",
    labelKey: "ram.locationList.deductiblesinMioEur",
    name: "ram.locationList.deductiblesinMioEur",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      const deductiblesInMioEur = row?.deductiblesInMioEur;
      if (deductiblesInMioEur !== null && deductiblesInMioEur !== undefined) {
        return formatNumber(Number(deductiblesInMioEur));
      }
    },
  },
  {
    key: "insurersRaiting",
    fieldName: "insurersRaiting",
    labelKey: "ram.locationList.insurersRating",
    name: "ram.locationList.insurersRating",
    minWidth: 150,
    maxWidth: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    isResizable: true,

    onRender: (row) => {
      if (!row?.insurersRaiting) return null;
      return row?.insurersRaiting;
    },
  },
];
